html {
  scroll-behavior: smooth;
}

.code-box {
  background-color: #FFFFFF;
  border: 1px solid #edf2f9;
  border-radius: 5px;
  padding: 20px !important;
}

body[data-theme="dark"] .code-box{
  background-color: #283142;
  border: none;
 
}

/* Ip Call Layout Styles */
@media (min-width: 992px) {
  .ant-menu-submenu-title .ant-menu-title-content{
    color: white;
  }
  
  .ant-menu-submenu-title .ant-menu-submenu-arrow{
    color: white;
  }
}

.main-app-menu.ant-menu-inline-collapsed .ant-menu-submenu-selected .ant-menu-submenu-title{
  background-color: white;
}

.main-app-menu.ant-menu-inline-collapsed .ant-menu-submenu-selected .ant-menu-title-content{
color: #9b1d5d;
}

.ant-layout-sider-children .ant-menu-item:not(.ant-menu-item-selected){
  color: white;
}

.ant-layout-sider-children .ant-menu-root .ant-menu-item-group-title{
  color: white !important;
}

body[data-theme="dark"] p{
  color: #d6d7dc;
}

body[data-theme="light"] p{
  color:rgba(0,0,0,.65);
}

.ant-table-thead .ant-table-cell{
  /* background-color: #ffffff !important; */
  padding: 10px !important;
  /* background-color: transparent !important; */
}

/* --- */

textarea{
  border-radius: 5px !important;
}

/* Estilos varios de elementos */

.no-card-title-padding .ant-card-head-title{
  padding: 0;
}

/* --- */

/* Modificacion antd estilos */

.ant-select-selector{
  padding: 1px 4px;
}

.ant-col-0{
  display: none !important;
}

.ant-pagination{
  display: flex;
  align-items: center;
}

.ant-select-selector{
  border-radius: 6px !important;
}

.ant-btn-default:hover{
  color: #9b1d5d !important;
  border-color: #9b1d5d !important;
}

.ant-menu { 
  font-weight: 300 !important;
}
.ant-menu-submenu-title { 
  border-radius: 0% !important;
  margin-left: 0px !important;
  width: 100% !important;
}

.ant-menu-item {
  border-radius: 0% !important;
  margin-left: 0px !important;
  width: 100% !important;
}

/* .ant-btn-icon-only,.ant-btn-sm{
    height: 30px!important;
    padding: 5px 6px!important;
    width: 30px;
} */
h3 {
  color: #136084 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}
/* --- */

.ant-table{
  color:rgba(0,0,0,.65) !important;
}


.app {
  margin: 1rem 4rem;
}

.app .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.app .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.app .ql-editor {
  min-height: 18em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

.ql-picker.ql-font {
  .ql-picker-item {
      font-size: 0;

      &:before {
          content: attr(data-value) !important;
          font-size: 14px;
      }
  }
}

.ql-font .ql-picker-options span[data-value="Ubuntu"]::before {
  font-family: "Ubuntu";
}

.ql-font .ql-picker-options span[data-value="Raleway"]::before {
  font-family: "Raleway";
}

.ql-font .ql-picker-options span[data-value="Roboto"]::before {
  font-family: "Roboto";
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

/* Set content font-families */
.ql-font-Roboto {
  font-family: 'Roboto';
}
.ql-font-Raleway {
  font-family: 'Raleway';
}
.ql-font-Montserrat {
  font-family: 'Montserrat';
}
.ql-font-Lato {
  font-family: 'Lato';
}
.ql-font-Rubik {
  font-family: 'Rubik';
}

.ql-picker.ql-font .ql-picker-label[data-value="roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before
{
    font-family: "Roboto", cursive;
    content: "Roboto";
}

.ql-picker.ql-font .ql-picker-label[data-value="markazi-text"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="markazi-text"]::before
{
    font-family: 'Markazi Text', serif;
    content: "Markazi";
}

.mirrorFlip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 150px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #e1e1e1;
}